var th_megamenu = {
    selectors: {
        overlayMenu: $("#overlay-menu"),
        inputSearch: $("#recherche-input"),
        menuBtn: $('.menu-item-has-children .inner-link:not(".inner-link-sub")'),
        retourBtn: $('.overlay-menu .btn-back'),
        innerContainer: $(".overlay-menu .inner-container"),
        firstButtonWithSubMenu: $(".overlay-menu .first-level-menu .menu-item-has-children:first button")
    },
    classes: {
        hasContent: "has-content",
        activeBtn: "active-btn",
        showArrowDown: "show-arrow-down",
        slideOutOpen: "slide-out-open",
    },
    variables: {
        lienActif: tr_lien_actif,
    },
    init: function () {
        th_megamenu.inputSearch();
        th_megamenu.menuBtnClick();
        th_megamenu.retourBtn();
        th_megamenu.onLoadShowArrowDown();
        th_megamenu.onScreenSizeChange();
    },
    inputSearch: function () {
        th_megamenu.selectors.inputSearch.focus(function () {
            $(this).parent().addClass(th_megamenu.classes.hasContent);
        });

        th_megamenu.selectors.inputSearch.focusout(function () {
            if (th_megamenu.selectors.inputSearch.val() !== "") {
                th_megamenu.selectors.inputSearch.parent().addClass(th_megamenu.classes.hasContent);
            } else {
                th_megamenu.selectors.inputSearch.parent().removeClass(th_megamenu.classes.hasContent);
            }
        });
    },
    menuBtnClick: function () {
        th_megamenu.selectors.menuBtn.on("click", function () {
            th_megamenu.selectors.menuBtn.each(function () {
                th_megamenu.removeActiveTextToTile($(this));
            });
            // On gère l'affichage de la classe active-btn qui permet de voir le sous-menu imbriqué
            if ($(this).hasClass(th_megamenu.classes.activeBtn)) {
                $(this).removeClass(th_megamenu.classes.activeBtn);

                th_megamenu.removeActiveTextToTile($(this));
                $(this).attr("aria-expanded", false);
                th_megamenu.selectors.overlayMenu.removeClass(th_megamenu.classes.showArrowDown);
                th_megamenu.selectors.innerContainer.removeClass(th_megamenu.classes.slideOutOpen);
            } else {
                $(".active-btn").removeClass(th_megamenu.classes.activeBtn);
                $(this).addClass(th_megamenu.classes.activeBtn);
                th_megamenu.addActiveTextToTitle($(this));
                $(this).attr("aria-expanded", true);
                th_megamenu.selectors.innerContainer.addClass(th_megamenu.classes.slideOutOpen);
                th_megamenu.subMenuTouchesWindow($(this));
            }

        });
    },
    retourBtn: function () {
        // Au click sur le bouton de retour en arrière, on remet le focus sur l'élément qui a déclenché l'ouverture
        th_megamenu.selectors.retourBtn.on("click", function () {
            var subMenuOpener = $(this).parent().prev();
            th_megamenu.selectors.innerContainer.removeClass(th_megamenu.classes.slideOutOpen);
            subMenuOpener.removeClass(th_megamenu.classes.activeBtn);
            th_megamenu.selectors.overlayMenu.removeClass(th_megamenu.classes.showArrowDown);
            subMenuOpener.focus();
        });
    },
    subMenuTouchesWindow: function (el) {
        var submenuContainer = el.parents(".overlay-menu");
        var submenuOffsetTop = el.next().find(".is-sub-menu").offset().top;
        var submenuHeight = el.next().find(".is-sub-menu").height();
        var windowHeight = $(window).height();
        var windowScrollTop = $(window).scrollTop();
        if ((windowHeight + windowScrollTop) < (submenuOffsetTop + submenuHeight)) {
            submenuContainer.addClass(th_megamenu.classes.showArrowDown);
        } else {
            submenuContainer.removeClass(th_megamenu.classes.showArrowDown);
        }
    },
    onLoadShowArrowDown: function () {
        th_megamenu.subMenuTouchesWindow(th_megamenu.selectors.firstButtonWithSubMenu);
    },
    openDefaultMenu: function () {
        th_megamenu.selectors.firstButtonWithSubMenu.addClass(th_megamenu.classes.activeBtn);
        th_megamenu.selectors.firstButtonWithSubMenu.attr("aria-expanded", "true");
        th_megamenu.addActiveTextToTitle(th_megamenu.selectors.firstButtonWithSubMenu);
    },
    closeDefaultMenu: function () {
        th_megamenu.selectors.firstButtonWithSubMenu.removeClass(th_megamenu.classes.activeBtn);
        th_megamenu.selectors.firstButtonWithSubMenu.attr("aria-expanded", "false");
    },
    onScreenSizeChange: function () {
        if (isTabletPortraitOrSmalller()) {
            th_megamenu.closeDefaultMenu();
        } else {
            th_megamenu.openDefaultMenu();
        }

        var resizeMenu = debounce(function () {

            if (isTabletPortraitOrSmalller()) {
                th_megamenu.closeDefaultMenu();
            } else {
                th_megamenu.openDefaultMenu();
            }
        }, 150);

        $(window).on('resize', resizeMenu);
    },
    addActiveTextToTitle: function (link) {
        link.attr("title", function () {
            return $(this).attr("title") + th_megamenu.variables.lienActif;
        });
    },
    removeActiveTextToTile: function (link) {
        link.attr("title", function () {
            return $(this).attr("title").replace(th_megamenu.variables.lienActif, '');
        });
    }

}

th_megamenu.init();
