// Dans le "bloc" sit à propos, on ajoute un bouton pour voir plus ou moins si le texte à dépassé une hauteur de 400px
var sitProposHeight = $('.single-sit .sit-a-propos .text');
var btnShowMorePropos = $('.single-sit .btn-view-more');

sitProposHeight.each(function () {
    if ($(this).height() >= 188) {
        $(this).parent().addClass('view-more-active');

        var currentAriaExpanded = false;
        btnShowMorePropos.on('click', function () {
            currentAriaExpanded = !currentAriaExpanded;

            $(this).prev().toggleClass('toggle-height');

            if (currentAriaExpanded) {
                $(this).attr("aria-expanded", true);
            } else {
                $(this).attr("aria-expanded", false);
            }

        });
    }
});