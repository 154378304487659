(function () {

    var cardGalerie = $(".bloc-galerie .image-link");

    cardGalerie.on("keydown", function (event) {
        if (event.keyCode === 32) {
            event.preventDefault();
            $(this).trigger("click");
        }
    });

})();