var th_barre_navigation = {
    selectors: {
        langDropDownMobile: $("#lang-dropdown-mobile"),
        liensHome: $("#barre-navigation .liens-home a"),
        lienHomeActive: document.querySelector("#barre-navigation .liens-home .is-active"),
        menuIndicator: document.querySelector("#barre-navigation .menu-indicator"),

    },
    variables: {
        menuIndicatorWidth: 0,
        targetWidth: null,
        targetLeft: null,
    },
    init: function () {
        th_barre_navigation.changementLangueMobile();
        th_barre_navigation.menuIndicator();
    },
    changementLangueMobile: function () {
        // Ce script est nécessaire pour forcer le changement de page au click sur une option
        th_barre_navigation.selectors.langDropDownMobile.change(function () {
            var val = $(this).val();
            window.location.href = val;
        });
    },
    menuIndicator: function () {

        for (var i = 0; i < th_barre_navigation.selectors.liensHome.length; i++) {

            th_barre_navigation.variables.menuIndicatorWidth = th_barre_navigation.selectors.menuIndicator.getBoundingClientRect().width;
            th_barre_navigation.selectors.liensHome[i].addEventListener('mouseover', th_barre_navigation.hoverLienHome);
            th_barre_navigation.selectors.liensHome[i].addEventListener('mouseout', th_barre_navigation.undoHoverLienHome);
            // initialise la position de l'indicateur sur le lien qui contient la classe "is-active"
            if (th_barre_navigation.selectors.liensHome[i].classList.contains("is-active")) {
                th_barre_navigation.updateIndicator(th_barre_navigation.selectors.liensHome[i]);
            }

        }
    },
    hoverLienHome: function (e) {
        var target = e.currentTarget;
        th_barre_navigation.updateIndicator(target);
    },
    undoHoverLienHome: function (e) {
        th_barre_navigation.updateIndicator(th_barre_navigation.selectors.lienHomeActive);
    },
    updateIndicator: function (target) {
        th_barre_navigation.variables.targetWidth = target.getBoundingClientRect().width;
        th_barre_navigation.variables.targetLeft = target.offsetLeft;
        var targetLeft = target.offsetLeft + (th_barre_navigation.variables.targetWidth / 2) - (th_barre_navigation.variables.menuIndicatorWidth / 2);
        th_barre_navigation.selectors.menuIndicator.style.transform = 'translateX(' + targetLeft + 'px)';
        th_barre_navigation.selectors.menuIndicator.style.opacity = '1';

        if (target.classList.contains('is-installer')) {
            th_barre_navigation.selectors.menuIndicator.style.backgroundColor = '#03A688';
        } else  {
            th_barre_navigation.selectors.menuIndicator.style.backgroundColor = '#DC1965';
        }
    }
}

th_barre_navigation.init();

