var slidersAncres = document.getElementsByClassName('slider-ancres');

for (var i = 0; i < slidersAncres.length; i++) {
    var sliderAncre = new Splide(slidersAncres[i], {
        pagination: false,
        autoplay: false,
        easing: "ease",
        autoWidth: true,
        focus: 0,
        omitEnd: true,
    });

    sliderAncre.mount();
}




