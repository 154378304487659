(function () {
    // Menu en slider en desktop
    const sliders = document.querySelectorAll('.js-slider-menu-secondary');
    sliders.forEach(function(slider) {
        const splide = new Splide(slider, {
            pagination: false,
            autoplay: false,
            autoWidth: true,
            focus: 0,
            omitEnd: true,
            padding: 20,

            breakpoints: {
                980: {
                    destroy: true,
                },
            }
        }).mount();
    });

    // Menu déroulant en tablette / mobile
    const btnToggleMenuSecondary = document.querySelector('.js-btn-toggle-menu-secondary');
    const menuSecondary = document.querySelector('.js-slider-menu-secondary');
    if (btnToggleMenuSecondary && menuSecondary) {
        btnToggleMenuSecondary.addEventListener('click', (e) => {
            menuSecondary.classList.toggle('is-open');
            btnToggleMenuSecondary.classList.toggle('is-open');
        });
    }

})();
