// PAGE SINGLE SIT
function callbackMapSingleSIT(macarte) {
    macarte.zoomControl.setPosition('topright');
    macarte.setZoom(14);
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// Page Itinéraire, carte

function callbackMapBlocItineraire(macarte) {
    mapiti = macarte;
    macarte.zoomControl.setPosition('topright');


    var departlatlng = {
        lat: $(macarte._container).data('departlat') * 1,
        lng: $(macarte._container).data('departlng') * 1
    };
    if (departlatlng.lat && departlatlng.lng) {
        var depart_text = $(macarte._container).data('departtext');

        var etapeIconDepart = L.divIcon({
            className: 'custom-etape-icon-actif',
            html: '<div class="marker-pin-actif"><span class="icon-ico-oeil"></span></div>',
            shadowUrl: null,
            iconSize: [30, 30],
            iconAnchor: [15, 15],
        });
        var markerdepart = th_maps.createMarker(macarte, departlatlng, etapeIconDepart);

    }

    // maps-popup
    if ($('.js-bloc-pois').length > 0) {
        var markers = [];

        $('.js-poi .card').each(function (index) {

            var etapeIcon = L.divIcon({
                className: 'poi-icon',
                html: '<div class="marker-pin" data-marker="' + (index + 1) + '"><span class="icon-ico-oeil"></span></div>',
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });


            if (this.getAttribute('data-lat') && this.getAttribute('data-lng')) {
                var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};

                if (latlng.lat && latlng.lng) {
                    var marker = th_maps.createMarker(macarte, latlng, etapeIcon);

                    // Lorsqu'on ouvre un popup on change l'icône pour ensuite remettre à la fermeture celle-là de default
                    marker.on("popupopen", (e) => setMarkerState(marker, 'hover'));
                    marker.on("popupclose", (e) => setMarkerState(marker, 'default'));

                    // Lorsqu'on fait un hover sur un marqueur on change l'icône
                    marker.on("mouseover", (e) => setMarkerState(marker, 'hover'));
                    marker.on("mouseout", (e) => setMarkerState(marker, 'default'));

                    // Lorsqu'on fait un hover sur une card, on change l'icône du marqueur
                    this.addEventListener('mouseenter', (e) => setMarkerState(marker, 'hover'));
                    this.addEventListener('mouseleave', (e) => setMarkerState(marker, 'default'));

                    // vu que les cards de base on du lazy load, on va supprimer ce qui pourrait bloquer l'affichage des images si le lazy n'est pas encore passé à ce moment là
                    let card_html = this.outerHTML;
                    let lazy_processed_tag = 'data-was-processed="true"';
                    let lazy_loaded = card_html.indexOf(lazy_processed_tag);

                    if (lazy_loaded == -1) {
                        card_html = card_html.replace('src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"', '');
                        card_html = card_html.replace('data-src=', 'src=');
                        card_html = card_html.replace('lazy', '');
                    }

                    th_maps.createInfoWindow(card_html, marker, 260);
                    markers.push(marker);

                }
            }
        });

        // Change l'état d'un marker
        function setMarkerState(marker, state) {
            if (state === 'hover') {
                marker._icon.classList.toggle('active')
                marker.setZIndexOffset(1000);        // passe le marker au-dessus des autres
            } else {
                marker._icon.classList.toggle('active')
                marker.setZIndexOffset(0);
            }
        }


    }

    console.log(markers);


    // marker chart elevation
    var count = 1;
    var markerselevation = [];
    $('.marker-elevation').each(function () {
        var elevationIcon = L.divIcon({
            className: 'custom-elevation-icon',
            html: '<div class="marker-pin" data-markerindex="' + count + '"></div>',
            iconSize: [12, 12],
            iconAnchor: [6, 6],
        });

        var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};
        var marker = th_maps.createMarker(macarte, latlng, elevationIcon);
        markerselevation.push(marker);

        count++;
    });

    if (markerselevation.length > 0) {
        var group = new L.featureGroup(markerselevation);
    }

    var mapMarkers = $(".leaflet-marker-icon");
    mapMarkers.each(function () {
        $(this).attr("tabindex", "-1");
    });

}

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// Page Itinéraire, graphique JS

if ($('#denivele').length > 0) {

    // Chart.js, lourd (170ko) mais beaucoup d'option dont des interractions
    // https://www.chartjs.org/

    //Récupération de la couleur de la page, et rajout de 80 à la fin du #hexa pour mettre la transparent de 50% pour le fill
    var chartColors = "#002C2E";
    var bgChartCurve = "#03A688";

    //Initialisation du graph avec les valeurs en DATA sur l'élément canvas généré en PHP en fonction des informations fournis dans le KML
    var YAxis = $('#denivele').attr('data-YAxis').split(',');
    //# 13039 axe des ordonnées minimum à 100
    YAxis.push(100);
    var XAxis = $('#denivele').attr('data-XAxis').split(',');


    var ctx = $('#denivele');
    var myChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: XAxis,
            datasets: [
                {
                    label: 'DÉNIVELÉ (m)',
                    data: YAxis,
                    borderColor: chartColors,
                    fill: true,
                    backgroundColor: bgChartCurve,
                    pointRadius: 0,
                    pointHoverRadius: 5,
                    pointHitRadius: 10,
                    pointBackgroundColor: "#DC1965",
                    pointBorderColor: "#002C2E",
                    pointBorderWidth: 2,
                    lineTension: 0.1,
                }
            ]
        },

        hover: {
            mode: 'index'
        },
        options: {
            responsive: true,
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        fontColor: "#FFF"
                    },
                    scaleLabel: {
                        display: false,
                        labelString: 'DISTANCE (km)',
                        fontStyle: "normal",
                        fontFamily: 'montserrat'
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        stepSize: 160,
                        fontColor: "#FFF"
                    },
                    gridLines: {
                        drawOnChartArea: true,
                        color: "#1A4243"
                    },
                    scaleLabel: {
                        display: false,
                        labelString: 'ALTITUDE (m)',
                        fontFamily: 'montserrat',
                        fontStyle: "normal",
                        padding: {
                            bottom: 20
                        }
                    }
                }],
            },
            tooltips: {
                bodyFontFamily: 'montserrat',
                titleFontFamily: 'montserrat',
                displayColors: false,
            },

        },
    });


    document.getElementById("denivele").onmousemove = function (evt) {
        var activePoint = myChart.getElementAtEvent(event);

        // make sure click was on an actual point
        if (activePoint.length > 0) {
            $('[data-markerindex=' + activePoint[0]._index + ']').addClass('active');
            //$('#overlay-itineraire-etape').addClass('open');
            // $('.shadow-overlay').addClass('open');
        } else {
            $('[data-markerindex]').removeClass('active');
        }
    };
}

