$(document).ready(function () {

    var headerHeight = parseInt(getComputedStyle(document.body).getPropertyValue('--header-height'));

    $("#scroll-down-anchor").on('click', function (event) {
        $('html, body').animate({
            scrollTop: $("#first-section").offset().top - headerHeight
        }, 800, function () {
            window.location.hash = "#first-section";
        });
    });


    var loaded = 'loaded-image';
    var loadedContainer = 'loaded-container';
    var images = $('.wrapper-image');
    images.each(function () {
        var img = $(this).find("img");
        var url = img.attr("src");
        img.attr('src', url).bind('load', function () {
            $(this).parents(".wrapper-image").addClass(loaded);
            $(this).parents(".images-container").addClass(loadedContainer);
        });
    });


});
