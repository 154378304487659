// Smooth scroll vers la carte - au click sur la miniature de la carte dans la barre sit
(function () {

    $(document).ready(function () {

        var headerHeight = parseInt(getComputedStyle(document.body).getPropertyValue('--header-height'));

        $("#carte-anchor").on('click', function (event) {

            if (this.hash !== "") {
                event.preventDefault();
                var hash = this.hash;

                $('html, body').animate({
                    scrollTop: $(hash).offset().top - headerHeight
                }, 800, function () {
                    window.location.hash = hash;
                });
            }
        });

        $(".btn-voir-dates").on('click', function (event) {
            if (this.hash !== "") {
                event.preventDefault();
                var hash = this.hash;

                $('html, body').animate({
                    scrollTop: $(hash).offset().top - headerHeight
                }, 800, function () {
                    window.location.hash = hash;
                });
            }
        });
    });

    let sliderGalerie = document.querySelector('.js-galerie-sit-slider');

    if (sliderGalerie) {
        const sliderGalerieSplide = new Splide(sliderGalerie, {
            pagination: false,
            autoplay: false,
            easing: "ease",
            lazyLoad: 'nearby',
            autoWidth: true,
            focus: 0,
            omitEnd: true,
        }).mount();
    }

    let sliderHoraires = document.querySelector('.js-slider-sit-horaires');


    let current_slide = document.querySelector('.js-slider-sit-horaires .current');
    if (current_slide) {
        current_slide = current_slide.getAttribute('data-numslide');
    }
    if (!current_slide) {
        current_slide = 0;
    }


    if (sliderHoraires) {
        const sliderHorairesSplide = new Splide(sliderHoraires, {
            pagination: false,
            autoplay: false,
            easing: "ease",
            lazyLoad: 'nearby',
            autoWidth: true,
            focus: 0,
            omitEnd: true,
        }).mount().go(current_slide - 1);

        //console.log(sliderHorairesSplide.go(current_slide - 1));
    }


    let sliderSejours = document.querySelector('.js-sejours-sit-slider');

    if (sliderSejours) {
        const sliderSejoursSplide = new Splide(sliderSejours, {
            pagination: false,
            autoplay: false,
            easing: "ease",
            lazyLoad: 'nearby',
            autoWidth: true,
            focus: 0,
            omitEnd: true,
        }).mount();
    }





    let sliderRelation = document.querySelector('.js-relation-sit-slider');

    if (sliderRelation) {
        const sliderRelationSplide = new Splide(sliderRelation, {
            pagination: false,
            autoplay: false,
            easing: "ease",
            lazyLoad: 'nearby',
            autoWidth: true,
            focus: 0,
            omitEnd: true,
        }).mount();
    }

})();


// A l'ouverture de l'overlay carte qu'on trouve dans les singles sit, on charge la carte
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-carte-detail-sit') {
        setTimeout(function () {
            th_maps.initSingleMap(document.querySelector('#carte-detail-sit'));
        }, 250);
    }
});