(function () {

    var btnFlottant = $(".floating-btn button");

    btnFlottant.on("focus", function (e) {
        var textContainer = $(this).find(".text-container");
        textContainer.css("opacity", "1");
        textContainer.css("visibility", "visible");
        $(this).keyup(function (e) {
            if (e.key === "Escape") {
                textContainer.css("opacity", "0");
                textContainer.css("visibility", "hidden");
            }
        });
    });

})();