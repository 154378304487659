(function () {

    var sliders = document.getElementsByClassName('js-bloc-carte-interactive-slider');

    for (var i = 0; i < sliders.length; i++) {
        var splide = new Splide(sliders[i], {
            pagination: false,
            autoplay: false,
            easing: "ease",
            lazyLoad: 'nearby',
            autoWidth: true,
            focus: 0,
            omitEnd: true,
            direction: "rtl",
            breakpoints: {
                980: {
                    direction: "ltr",
                },
            }
        }).mount();
    }

    var blocCarteInteractive = {
        bloc: $(".bloc-carte-interactive"),
        slider: null,
        sliderWrapper: null,
        cards: null,
        map: null,
        mapZones: null,
        currentSlide: null,

        init: function () {
            this.cards = $(".slide", this.bloc);
            this.map = this.bloc.find("#MAP");
            this.mapZones = this.map.find("#zones [id^=zone]");
            this.dataZones = this.map.find("[data-zone]");
            this.villes = this.bloc.find("#villes [id^=ville]");
            this.elementsList = [];
            this.hoverEffect();
        },

        hoverEffect: function () {
            let _self = this;

            this.mapZones.each(function () {

                let currentZone = $(this);

                let currentId = $(this).attr("id");
                let zoneNumber = currentId.match(/\d/g);
                zoneNumber = zoneNumber.join("");

                let currentDataZone = $(_self.map).find("[data-zone='" + currentId + "']");
                // Au hover on change la couleur du dot décoratif
                $(this).on("mouseover", function () {
                    _self.changeColor($(this), currentDataZone);
                });

                // On remet ça couleur originale au mouseleave
                $(this).on("mouseleave", function () {
                    _self.backToOriginalColor($(this), currentDataZone);
                });

                // On change la couleur au click
                $(this).on("click", function () {
                    $(this).off("mouseleave");
                    _self.mapZones.each(function () {
                        $(this).removeClass("zone-is-active");
                    });
                    _self.villes.each(function () {
                        $(this).removeClass("ville-is-active");
                        $(this).removeClass("zone-is-hovered");
                    });
                    _self.changeColor($(this), currentDataZone);
                    splide.go(zoneNumber - 1);
                });

                splide.on("active", function () {
                    if (splide.index == (zoneNumber - 1)) {
                        _self.changeColor(currentZone, currentDataZone);
                    } else {
                        _self.backToOriginalColor(currentZone, currentDataZone);
                    }
                });

            });

            // Au click sur une ville on affiche la bonne slide
            this.villes.each(function () {
                let currentVille = $(this);
                let currentId = $(this).attr("id");
                let villeNumber = currentId.match(/\d/g);
                villeNumber = villeNumber.join("");
                let currentDataZone = $(_self.map).find("[data-zone='" + currentId + "']");


                _self.elementsList.push(currentId);
                // On change la couleur au click
                $(this).on("click", function () {
                    splide.go(villeNumber - 1);
                    _self.villes.each(function () {
                        $(this).removeClass("ville-is-active");
                        $(this).removeClass("zone-is-hovered");
                    });
                    _self.mapZones.each(function () {
                        $(this).removeClass("zone-is-active");
                    });
                    _self.highlightCity($(this));
                });

                splide.on("active", function () {
                    if (splide.index == (villeNumber - 1)) {
                        _self.highlightCity(currentVille);
                    } else {
                        _self.deactivateHighlightCity(currentVille);
                    }
                });
            });
        },
        changeColor: function (el, currentPoints) {
            // console.log("el", el);
            // console.log("currentPoints", currentPoints);
            for (var i = 0; i < currentPoints.length; i++) {
                $(currentPoints[i]).addClass("zone-is-hovered");
            }
            el.addClass("zone-is-active");
        },
        backToOriginalColor: function (el, currentPoints) {
            for (var i = 0; i < currentPoints.length; i++) {
                $(currentPoints[i]).removeClass("zone-is-hovered");
            }
            el.removeClass("zone-is-active");
        },
        highlightCity: function (el) {
            el.addClass("ville-is-active");
        },
        deactivateHighlightCity: function (el) {
            el.removeClass("ville-is-active");
        }

    }

    blocCarteInteractive.init();


})();












