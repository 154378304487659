// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors: {
        mapoverlay: '#map-listing'
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function (html) {
        // si composant favoris :
        th_favoris.parseItems();
        th_favoris.addEvents();
        parseCardSitClickOverlay();

        // gestion de l'affichage ou non du bouton pour voir la carte
        var nb_results = parseInt($(html).find(listingSitDefault.options.selectors.nb_results).first().text());
        if (nb_results > 100) {
            $('.js-listing-btn-showmap')
                .prop('aria-disabled', 'true')
                .prop('disabled', 'true')
                .addClass('deactivated-btn');
        } else {
            $('.js-listing-btn-showmap')
                .removeAttr('aria-disabled')
                .removeAttr('disabled')
                .removeClass('deactivated-btn');
            th_overlay.parse($('.js-listing-btn-showmap'));
        }
    }
});


// listing CPT - pas de map
var listingCPT = new thListingManager({
    selectors: {
        listing: '.js-listing-cpt',
        mapoverlay: '#map-listing'
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if (listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
            if (listingCPT.loaded) {
                listingCPT.initMapOverlay();
            }
        }, 250);
    }
});

// RGAA : Au focus sur le bouton, si l'utilisateur appuye sur la touche "ESC" on cache la tooltip
var btnShowMapListing = $('.js-listing-btn-showmap');
btnShowMapListing.on("focus", function (e) {
    $(this).find(".tooltiptext").css("display", "block");
    $(this).keyup(function (e) {
        if (e.key === "Escape") {
            $(this).find(".tooltiptext").css("display", "none");
        }
    });
});