// Gestion de l'attribut aria-expanded, lorsque l'utilisateur affiche ou cache un menu
var btnTable = $("#btn-control-table");

btnTable.on("click", function () {

    if ($(this).attr("aria-expanded") == "false") {
        $(this).attr("aria-expanded", true);
    } else {
        $(this).attr("aria-expanded", false);
    }

});