var init_rgaa_focus = {
    overlaySearch: 'overlay-search',
    overlayFavourites: 'overlay-favoris',
    overlayMenu: 'overlay-menu',
    btnMenu: '.btn-menu-open-wrapper .btn-menu',

    init: function () {
        init_rgaa_focus.onOverlayOpen();
        init_rgaa_focus.onOverlayClose();
    },

    onOverlayOpen: function () {
        // Initialisation de la librairie pour gérer le focus dans les overlays
        th_overlay.addCallbackOpen(function (callback) {
            var el = $("#" + callback);
            if (callback !== init_rgaa_focus.overlaySearch && callback !== init_rgaa_focus.overlayFavourites) {

                // SetTimeout ajouté afin que la librairie puisse bien récupérer tous les éléments "focusables" dans les overlays,
                // et donc trouver le premier et le dernier élément.
                // Si on n'as pas cette décalage, dans les overlays preview sit ou on injecte de l'html en ajax, la librairie n'arrive pas à récupérer le premier et dernier élément "focusable",
                // et donc ça ne marche pas
                setTimeout(function () {
                    th_rgaa_focus.init(el);
                }, 1000);
            }

            if (callback === init_rgaa_focus.overlayFavourites) {
                var firstEl = $(".btn-close", el)[0];
                var lastEl = $(".btn-email", el)[0];
                th_rgaa_focus.init(el, firstEl, lastEl);
            }
        });
    },

    onOverlayClose: function () {
        // Lorsqu'on ouvre un overlay imbriqué dans l'overlay menu (comme l'overlay favoris ou de recherche)
        // à l'ouverture d'un ces overlays le focus est mis sur le btn d'ouverture du menu (parce que l'overlay menu se ferme pour ouvrir les autres, donc le focus bouge sur l'élément qui à déclenché
        // l'ouverture de l'overlay menu)
        // On essaie donc de régler ce souci grâce à la variable noFocusOnPreviousOverlay
        th_overlay.addCallbackClose(function (callback) {
            if (callback === init_rgaa_focus.overlayMenu) {
                setTimeout(function () {
                    var overlaysNoBackToMenu = [];
                    overlaysNoBackToMenu.push($("#" + init_rgaa_focus.overlayFavourites));
                    overlaysNoBackToMenu.push($("#" + init_rgaa_focus.overlaySearch));
                    for (var i = 0; i < overlaysNoBackToMenu.length; i++) {
                        if (overlaysNoBackToMenu[i].hasClass("open")) {
                            th_overlay.noFocusOnPreviousOverlay = true;
                        }
                    }
                }, 100);
            }

            if (callback === init_rgaa_focus.overlayFavourites || callback === init_rgaa_focus.overlaySearch) {
                setTimeout(function () {
                    $(init_rgaa_focus.btnMenu).focus();
                });
            }
        });
    },

};


init_rgaa_focus.init();







