(function () {

    var sliders = document.getElementsByClassName('js-cover-sejour-slider');

    for (var i = 0; i < sliders.length; i++) {

        new Splide(sliders[i], {
            pagination: false,
            autoplay: false,
            easing: "ease",
            lazyLoad: 'nearby',
            autoWidth: true,
            focus: 0,
            direction: 'rtl',
            omitEnd: true,
            breakpoints: {
                980: {
                    direction: 'ltr',
                },
            }
        }).mount();
    }
})();